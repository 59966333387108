
@media (min-width: 1000px) {
    .b {
        position: absolute;
        width: 500px;
        right: 5%;
        top: 5%;
        z-index: 34;
    }

    .carouselPicture {
        position: relative;
        /*height: 300px;*/
        width: 100%;
    }
}

@media (min-width: 1920px) {

    .carouselPicture {
        position: relative;
        /*height: 300px;*/
        width: 100%;
    }
}

@media (max-width: 1366px) {

    .carouselPicture {
        position: relative;
        /*height: 300px;*/
        width: 100%;
    }
}

.insurance_product {
    background: #fff;
    border: 1px solid #e3e3e3;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    border-radius: 8px;
    font-size: 1.6rem;
    font-weight: 400;
    text-align: center;
    margin: auto;
}

.insurance_product_in {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    margin: 0;
}

.insurance_product_in2 {
    box-sizing: border-box;
    display: block;
    max-width: 100%;
}

.img_insurance_product {
    max-width: 100%;
    display: block;
    margin: 0;
    border: none;
    padding: 0;
}

.single-block {
    background: #fff;
    text-align: center;
    border: 7px solid rgba(169, 176, 180, 0.1);
    padding: 30px 30px 30px 30px;
    margin-top: 50px;
}

@media (max-width: 979px) {
    .single-block {
        background: #fff;
        text-align: center;
        margin-top: 50px;
        border: 0px;
        padding: 0px 0px 0px 0px;
    }
}

.single-block img {
    margin: 0 auto 35px auto;
}

.btn-insubee-orange {
    background-color: #FFDF00;
    font-weight: bold;
}

/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {

    .customHomeHeight {
        height: 265px !important;
    }

    .page-wrapper {
        min-height: 60vh;
    }

    /*.fixed-top {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
        height: auto;
    }*/

    .dropdown-menu-end {
        left: auto !important;
        text-align: center;
        border: 0;
    }

    .play-image {
        cursor: pointer;
        position: absolute;
        top: 32%;
        left: 39%;
        width: 80px;
        height: 80px;
    }

    /*    .customCOL {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 10px;
    }*/
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .play-image {
        cursor: pointer;
        position: absolute;
        top: 30%;
        left: 38%;
        width: 80px;
        height: 80px;
    }

    .customHomeHeight {
        height: 265px !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .play-image {
        cursor: pointer;
        position: absolute;
        top: 32%;
        left: 41%;
        width: 100px;
        height: 100px;
    }

    .customHomeHeight {
        height: 215px !important;
    }
    /*
    .customCOL {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 10px;
    }*/
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .play-image {
        cursor: pointer;
        position: absolute;
        top: 32%;
        left: 41%;
        width: 100px;
        height: 100px;
    }

    .customHomeHeight {
        height: 215px !important;
    }
    /*
    .customCOL {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 10px;
    }*/
}

.c-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
}

















/* Newest Design */

.HomeGCIS {
    margin-bottom: 3rem;
    margin-top: 4rem;
    padding-left: 3%;
    padding-right: 3%;
}

.HomeFlex {
    display: flex;
    height: 600px;
    margin: 15px;
}

.bckgColor-content {
    padding: 70px 0px 0px 110px
}

.Homecolumn {
    display: flex;
    justify-content: center;
}

.ChatText {
    position: absolute;
    width: 700px;
    padding: 30px;
    margin: 50px;
    display: flex;
}

    .ChatText p {
        max-width: 297px;
    }

.Homecolumn2 {
    /*flex: 30%;*/
    padding: 5px;
}

.HomeChracterImg {
    margin-left: -190px;
    margin-top: 15px;
}

.ObjFit {
    height: 80vh;
    object-fit: cover;
}

.bckgColor {
    height: 190px;
    width: 100%;
    background-color: #F3F7FF;
    margin-top: -300px;
}

.IProductContainer {
    width: 250px;
    height: 300px;
    margin-left: 50px;
    margin-top: -60px;
    text-align: center;
}

    .IProductContainer h3 {
        margin-bottom: 0px;
    }

#ChatBoxHome {
    content: url("../../image/Home/ChatBox.svg");
    width: 100%;
}

#InsubeeCharacter {
    content: url("../../image/Home/character.png");
}


/* Extra large devices (large laptops and desktops, 1800px and up) */
@media only screen and (min-width: 1500px) {
    .HomeFlex {
        padding-left: 190px;
        padding-right: 190px;
        margin-left: 50px;
        margin-right: 50px;
    }

    .bckgColor-content {
        margin-left: 230px;
    }
}

/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {
    .HomeGCIS {
        margin-bottom: 0rem;
    }

    .HomeFlex {
        display: block;
        height: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .ChatText {
        width: auto;
        display: block;
        text-align: center;
        margin: auto;
    }

        .ChatText p {
            margin: 0 auto;
        }

    .IProductContainer {
        margin: auto;
    }

        .IProductContainer h3 {
            font-size: 20px;
        }

    .HomeChracterImg {
        margin-top: -125px;
        margin-left: 30px;
    }

    .bckgColor {
        height: 100px;
        margin-top: 0px;
    }

    .bckgColor-content {
        padding: 30px 20px;
    }

        .bckgColor-content img {
            height: 50px;
        }
}


.carousel-caption {
    color: black;
    text-align: left;
    margin-left: -82px;
    margin-bottom: 80px;
}

    .carousel-caption h1 {
        font-size: 50px;
    }

    .carousel-caption p {
        max-width: 320px;
        font-size: 18px;
    }

    .carousel-caption div {
        width: 250px;
    }

/* FOR CAROUSEL */
/* Extra large devices (large laptops and desktops, 1800px and up) */
@media only screen and (min-width: 1500px) {
    .ObjFit {
        height: 86.5vh;
    }

    .carousel-caption {
        margin-left: -20px;
        margin-bottom: 215px;
    }
}

/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {
    .carousel-caption {
        color: black;
        text-align: center;
        margin-left: 0px;
        margin-bottom: 300px;
    }

        .carousel-caption h1 {
            font-size: 33px;
        }

        .carousel-caption p {
            margin: auto;
            padding: 1rem;
            font-size: 14px;
        }

        .carousel-caption div {
            width: 80%;
            margin: auto;
        }
}


.HomeHIIW {
    text-align: center;
    margin: 5.5rem 6rem 3rem 6rem;
}

/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {
    .HomeHIIW {
        margin: 5rem 1rem 3rem 1rem;
    }

    .HIIWTxt {
        width: 292px;
        margin: auto;
    }

        .HIIWTxt div {
            width: 174px;
            margin: auto;
            margin-bottom: 1rem;
        }

    #ChatBoxHome {
        content: url("../../image/Home/ChatBox_Mobile.svg");
        width: 100%;
    }

    #InsubeeCharacter {
        content: url("../../image/Home/character_Mobile.png");
        width: 100%;
    }
}

/* Tablets and Small Desktops */
@media only screen and (min-width: 768px) and (max-width: 1023px) {

    .HIIWTxt {
        width: 500px;
        margin: auto;
    }

    .HomeChracterImg {
        width: 300px;
    }

    .IProductContainer {
        margin-left: 0;
        margin-top: 0;
    }

    .IProductContainer-img {
        height: 100px;
        width: 100px;
    }

    .ChatText {
        width: 550px;
        padding: 20px;
        margin: 10px;
    }

        .ChatText div:first-child {
            width: 200px;
        }

        .ChatText h1 {
            font-size: 20px;
        }

        .ChatText p {
            font-size: 14px;
        }

    .bckgColor {
        margin-top: -410px;
    }

    /*#ChatBoxHome {
        content: url("../../image/Home/ChatBox_Mobile.svg");
        width: 100%;
    }*/

}

/* Medium Desktops and Larger */
@media only screen and (min-width: 1024px) {

    .HIIWTxt {
        width: 798px;
        margin: auto;
    }
}

.HIIWComponentVariation {
    width: 600px;
    margin-left: 5rem;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.homeVariation-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 3;
    padding: 1rem;
}

    .homeVariation-flex .text-content {
        width: 524px;
        text-align: center;
    }

        .homeVariation-flex .text-content p {
            font-size: 40px;
            font-family: poppins;
        }

.homeVariation-bg-container {
    background: #FFF08A;
    opacity: 1;
    position: relative;
}

.HIIWComponent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-left: 100px;
    padding-right: 100px;
}

.InsCmptBox {
    display: flex;
    flex-wrap: wrap;
    width: 300px;
    height: 274px;
}

.InsCmptItem {
    text-align: center;
    color: black;
    background-color: white;
    margin: 12px;
    padding: 12px;
    width: 268.06px;
    height: 290.06px;
    border-radius: 15px;
    border: 2px solid #D8D8D8;
}

.HomeTestimonials {
    text-align: center;
    padding-top: 4rem;
    margin-top: 3rem;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
}

.HomeTestimonialsBGImg {
    background-image: url("../../image/Home/TestimonialsInsubeeLogoBG.svg");
    background-repeat: no-repeat;
    background-position: right;
}

.HomeTestimonials-txt {
    width: 443px;
    margin: auto;
}


/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {
    .HomeTestimonials-txt {
        width: 100%;
    }
}

.HomeVLinearGradient {
    height: 594px;
    background-color: transparent;
    background-image: linear-gradient(transparent, transparent, transparent, #FFFFFF);
    margin-top: -594px
}

.SliderContainer .SliderItems {
    width: 370px;
    height: 234px;
    border: 2px solid #D8D8D8;
    border-radius: 15px;
    padding: 20px 25px 20px 30px;
    text-align: center;
}

.testimonialSC .slick-slide {
    width: 370px !important;
    margin-left: 25px;
    margin-bottom: 25px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 10px 10px 0px rgba(191,191,191,1);
}

.testimonialSC .slick-list {
    height: 250px;
}

.SliderAvatarName {
    text-align: left;
    margin-bottom: 10px;
}

    .SliderAvatarName span {
        margin-top: 10px;
        position: fixed;
        margin-left: 20px;
        font-size: 18px;
    }

.SliderItems p {
    font-size: 14px;
    text-align: left;
}

.FiveMinContainer {
    padding: 200px 20px 20px 80px;
}

    .FiveMinContainer.row {
        margin: auto;
    }

.FiveMinBG {
    background-image: url("../../image/Home/Banner5Minutes.png");
    background-repeat: no-repeat;
    height: 312.52px;
    background-size: cover;
}


.FiveMinTxt span {
    font-size: 18px;
}

.FiveMinBtn-box {
    width: 250px;
    margin-top: 10px;
    margin-left: 65px;
}

.FaqContainer {
    margin: 6rem 10rem 5rem 10rem;
    padding-left: 5rem;
    padding-right: 5rem;
    text-align: center;
}

.faqBox {
    width: 554.15px;
    text-align: left;
    margin: auto;
}

.FaqTitle span {
    color: #BDBDBD;
}

.FaqQ.active:after, .FaqQ.active:before, .FaqQ:before {
    content: "";
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 1;
    position: absolute;
    right: 1rem;
    vertical-align: middle;
    width: 25px;
}

.FaqQ:before {
    background-image: url("../../image/Home/plus.svg");
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

.FaqQ.active:before {
    background-image: url("../../image/Home/Arrow_Down.svg");
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

.FaqAPanel {
    width: 479px;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}

    .FaqAPanel span {
        font-size: 14px;
    }

/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {
    .FiveMinBG {
        background-image: url("../../image/Home/5MinBanner.png");
        height: 100%;
        background-position: center;
    }

    .FiveMinContainer {
        padding: 18rem 2rem 3rem 2rem;
    }

    .FiveMinTxt {
        margin: auto;
        text-align: center;
        width: 272px;
        margin-bottom: 10px;
    }

        .FiveMinTxt span {
            font-size: 14px;
        }

    .FiveMinBtn-box {
        margin: auto;
    }


    .FaqContainer {
        padding: 1rem 1rem 3rem 1rem;
        margin: 3rem 1rem 2rem 1rem;
    }

    .faqBox {
        width: 327px;
    }

    .FaqQ {
        width: 240px;
    }

    .FaqAPanel {
        width: 285px;
        margin-top: 0.9rem;
    }
}

.HomeBlog {
    height: 795px;
    background-color: #FFDF00;
    padding: 5rem;
}

.HomeBlogTxt {
    width: 664px;
    margin-top: 10px;
}

    .HomeBlogTxt span {
        font-size: 18px;
    }


/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {
    .HomeBlog {
        padding: 5rem 2rem 5rem 2rem;
    }

    .HomeBlogTxt {
        width: 320px;
        margin-bottom: 15px
    }

        .HomeBlogTxt span {
            font-size: 14px;
        }
}


.BlogSL .BSliderItems {
    height: 425px;
    background-color: white;
    width: 380px !important;
    border-radius: 15px;
}

    .BlogSL .BSliderItems img {
        border-radius: 15px 15px 5px 5px;
    }

.BSliderTxt {
    padding: 2rem;
}

    .BSliderTxt #TeamInsubeeTxt {
        font-size: 13px;
        color: #BDBDBD;
    }

    .BSliderTxt span {
        font-size: 14px;
    }

.btnInsubeeReadMore {
    font-size: 13px;
    text-align: center;
    background-color: #E5E5E5;
    font-family: 'Open Sans';
    font-weight: bolder;
    border-radius: 50px;
    width: 152px;
    height: 30px;
    color: #EECF00;
    border: 1px solid #F5F5F5;
    padding: 0px;
}

.BlogSL .slick-prev:before, .BlogSL .slick-next:before {
    font-family: 'Open Sans';
    font-size: 45px;
    line-height: 0;
    color: transparent;
}


.slick-prev:before {
    background-image: url("../../image/Home/Arrow_Left.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: -35px;
}

.slick-next:before {
    background-image: url("../../image/Home/Arrow_Right.svg");
    background-size: contain;
    background-repeat: no-repeat;
}


/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {
    .slick-next:before {
        margin-left: -20px;
    }

    .slick-prev:before {
        margin-left: -5px;
    }

    .slick-arrow {
        z-index: 5;
    }
}


/*  About Us */
.WhoWeAre-Content {
    width: 515px;
    margin-left: 30rem;
    height: 280px;
    padding-top: 3.5rem;
}

.AboutUs-bgImg {
    background-image: url("../../image/AboutUs/AboutUsBImg.svg");
    background-repeat: no-repeat;
    height: 176px;
}

.AboutUs-Img1 {
    position: absolute;
    margin-top: -282px;
    left: 5rem;
}

.OurVision-Content {
    margin-left: 30rem;
    width: 515px;
    padding-top: 2rem;
}

.WhatWeDo-box {
    width: 100%;
    margin: auto;
    height: 280px;
}

.AboutUs-Img2 {
    position: absolute;
    right: 0;
    margin-top: -125px
}

.WhatWeDo-Content {
    margin-left: 20rem;
    width: 577px;
}


/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {
    .WhoWeAre-Content {
        width: 299px;
        margin: auto;
        height: auto;
        padding-top: 0;
    }

    .AboutUs-bgImg {
        background-position: right;
    }

    .AboutUs-Img1 {
        position: unset;
        margin-top: 0;
        max-height: 100%;
        max-width: 100%;
        float: left;
    }

    .OurVision-Content {
        margin-left: 0;
        width: auto;
        padding-top: 2rem;
    }

    .AboutUs-Img2 {
        position: unset;
        margin-top: 0;
        float: right;
        max-height: 211px;
        max-width: 168px
    }

    .WhatWeDo-Content {
        margin-left: 0;
        width: auto;
    }
}


/* contactUs */

.contactUs-presentation {
    padding: 0rem 5% 5rem;
    position: relative;
    z-index: 2;
}

.contactUs-container {
    display: flex;
    justify-content: space-between;
}

.contactUs-form-container {
    width: 50%;
}

.contactUs-image-container {
    width: 50%;
}

.contactUs-container--1 {
    display: flex;
    align-items: center;
}

    .contactUs-container--1 img {
        flex: 0 0 auto;
        margin-right: 20px;
        margin-top: -15px;
        height: 22px;
        width: 22px;
    }

    .contactUs-container--1 .contactUs-text {
        flex: 1 1 auto;
    }

@media (max-width: 768px) {
    .contactUs-container {
        flex-direction: column;
    }

    .contactUs-form-container {
        width: 100%;
    }

    .contactUs-image-container {
        display: none;
    }
}


/* Landing Page A & B */

.HomeVariation-info .rw-widget-input, .HomeVariation-infoB .rw-widget-input {
    border-radius: 50px;
    height: 50px;
    border: 1px solid #575757;
}

.HomeVariation-info .rw-popup, .HomeVariation-infoB .rw-popup {
    width: 100%;
}

.HomeVariation-info .contactUs-presentation {
    padding: 5rem 5%;
}

.HomeVariation-infoB .contactUs-presentation {
    padding: 2rem 3%;
}

.HomeVariation-info .contactUs-form-container {
    width: 636px;
    height: 648px
}

.HomeVariation-infoB .contactUs-form-container {
    color: white;
    background-color: rgba(0, 0, 0, 33%);
    padding: 2rem;
    width: 100%;
    max-width: 636px;
    height: 769px;
    /*height: 648px;*/
    /*max-height: 648px*/
}

.HomeVariation-infoB h1, .HomeVariation-infoB h4 {
    color: white;
}

.carouselVariation-textbox {
    width: 530px !important;
    color: white;
    background-color: rgba(0, 0, 0, 33%);
}

.HomeTestimonials-variation {
    padding-top: 2rem;
    margin-top: 1rem
}

.btnInsubeeR-variation {
    font-size: 18px;
    text-align: center;
    font-family: 'Poppins';
    font-weight: bolder;
    border-radius: 50px;
    width: 100%;
    height: 48px;
    background-color: #FFDF00;
    color: black;
    border: 1px solid #FFDF00;
}

    .btnInsubeeR-variation:hover {
        background-color: black;
        color: #FFDF00;
        border: 1px solid black;
    }

/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {
    .homeVariation-flex, .HIIWComponentVariation {
        flex-direction: column;
    }

    .HIIWComponentVariation {
        width: 100%;
        margin-left: 0;
    }

    .HomeTestimonialsBGImg {
        padding-top: 1rem;
    }

    .HomeVariation-info .contactUs-form-container {
        width: 351px;
        height: 890px;
    }

        .HomeVariation-info .contactUs-form-container h4 {
            width: 226px;
        }

    .homeVariation-flex .text-content {
        width: 100%;
    }

    .variation-mobile {
        width: 100%;
        left: 0;
        margin-bottom: 0;
        bottom: 0;
        color: white;
        background-color: rgba(0, 0, 0, 33%);
    }

        .variation-mobile h1 {
            color: white;
        }

    .variationImg-mobile {
        height: 591px;
        width: auto;
    }

    .HomeVariation-infoB .contactUs-form-container {
        height: 100%;
    }

    .LandingB-mobile {
        margin-bottom: 0;
        bottom: 0;
        left: 0;
        position: absolute;
    }
}

/* Tablets and Small Desktops */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .homeVariation-flex {
        flex-direction: column;
    }

    .HIIWComponentVariation {
        width: 100%;
        margin-left: 0;
    }

    .FaqContainer {
        padding: 1rem 1rem 3rem 1rem;
        margin: 3rem 1rem 2rem 1rem;
    }
}