@import url('https://fonts.googleapis.com/css2?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans');

body {
    font-size: 12px;
    padding-top: 60px;
    font-family: "Open Sans",'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@media (max-width: 979px) {
    body {
        padding-top: 80px;
    }
}
/*.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    height: 80px !important;
    padding: 0 !important;
}*/

/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {
    .page-wrapper {
        min-height: 60vh;
    }
    /*.fixed-top {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
        height: auto ;
    }*/
    .dropdown-menu-end {
        left: auto !important;
        text-align: center;
        border: 0;
    }

    .card-payment-success {
        min-height: 600px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .page-wrapper {
        min-height: 60vh;
    }
    /*.fixed-top {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
        height: auto;
    }*/
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .page-wrapper {
        min-height: 60vh;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .page-wrapper {
        min-height: 100vh;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .page-wrapper {
        min-height: 100vh;
    }
}

.dropdown-menu-end {
    left: auto !important;
}


.table-responsive {
    display: table;
}

.insubeeFooter {
    bottom: 0;
    position: absolute;
    right: 0;
    color: #74788d;
    left: 250px;
    height: 60px;
    background-color: #f2f2f5;
}

.customLabel {
    font-size: 16px;
    font-family: 'Open Sans';
}

.customRequired::after {
    content: " *";
    color: red;
}



textarea {
    height: 105px !important;
}

btn, button, a {
    outline: 0px dotted;
}

.nav-link {
    /* display: block; */
    padding-bottom: 1px;
    /*    padding-left: 0px;
    padding-right: 0px;*/
}

p, span, li {
    font-family: 'Open Sans';
    font-size: 16px;
}


li {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 15px;
    font-weight: 100;
}

.zindex-1 {
    z-index: 0;
}

.zindex-0 {
    z-index: 300;
}

.zindex-200 {
    z-index: 200;
}

.defaultLayout {
    padding-top: 0px;
}


.invalid-feedback2 {
    /*top: 50px;
    color: red;
    font-size: 12px;*/
    width: 100%;
    margin-top: 0.25rem;
    margin-left: 1.5rem;
    font-size: 80%;
    color: #f46a6a;
}

.invalid-feedback2White {
    top: 50px;
    color: white;
    font-weight: 100;
    font-size: 12px;
}

label {
    font-size: 14px;
}


.textboxlabel {
    color: black;
    font-size: 11px;
    font-family: 'Montserrat';
}

.textboxlabelPhone {
    color: black;
    font-size: 10px;
    padding-left: 10px;
}

input:focus {
    outline: none;
}

button:focus {
    outline: none;
}

.inputTextbox {
    width: 100%;
    height: 50px;
    font-size: 15px;
    padding-top: 20px;
    padding-left: 15px;
    border: 1px solid #c8ced3;
    border-radius: 5px;
}

    .inputTextbox:active {
        border: unset;
    }

/*for drop down list*/
.inputTextbox1 {
    width: 100%;
    font-size: 15px;
}


.inputTextbox2 {
    width: 90%;
    height: 50px;
    padding-top: 20px;
    padding-left: 15px;
    font-size: 15px;
    border: 1px solid #c8ced3;
    border-radius: 5px;
}

.inputTextbox3 {
    width: 79%;
    height: 50px;
    font-size: 15px;
    padding-top: 20px;
    padding-left: 15px;
    border: 1px solid #c8ced3;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.inputTextbox4 {
    position: relative;
    width: 100%;
    height: 50px;
    font-size: 15px;
    padding-top: 20px;
    padding-left: 15px;
    border: 1px solid #c8ced3;
}

.inputTextbox5 {
    position: relative;
    width: 100%;
    height: 50px;
    font-size: 15px;
    padding-top: 20px;
    padding-left: 15px;
    border: 1px solid #c8ced3;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}


.lowerindex {
    position: absolute;
    display: block;
    z-index: 2;
    padding-left: 15px;
}

.rw-input, .rw-dropdown-list-autofill, .rw-filter-input {
    padding-top: 20px;
    font-weight: 100;
}


.invalid-feedback {
    color: red;
    font-size: 15px;
}

a:hover {
    cursor: pointer;
    color: #EECF00 !important;
}

.starter-template {
    padding: 3rem 1.5rem;
    text-align: center;
}

/*Header color on default page*/
.bg-light {
    background-color: #ffffff !important;
}

.bg-grey-insubee {
    background-color: #004080 !important;
}

.mygdexFont {
    font-size: 14px;
    font-weight: bold;
}

.mygdexFontNormal {
    font-size: 16px;
}


.modal-login {
    width: 320px;
}

    .modal-login .modal-content {
        border-radius: 1px;
        border: none;
    }

    .modal-login .modal-header {
        position: relative;
        justify-content: center;
        background: #f2f2f2;
    }

    .modal-login .modal-body {
        padding: 30px;
    }

    .modal-login .modal-footer {
        background: #f2f2f2;
    }

    .modal-login h4 {
        text-align: center;
        font-size: 26px;
    }

    .modal-login label {
        font-weight: normal;
        font-size: 13px;
    }

    .modal-login .form-control, .modal-login .btn {
        min-height: 38px;
        border-radius: 2px;
    }

    .modal-login .hint-text {
        text-align: center;
    }

    .modal-login .close {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .modal-login .checkbox-inline {
        margin-top: 12px;
    }

    .modal-login input[type="checkbox"] {
        margin-top: 2px;
    }

    .modal-login .btn {
        min-width: 100px;
        background: #3498db;
        border: none;
        line-height: normal;
    }

        .modal-login .btn:hover, .modal-login .btn:focus {
            background: #248bd0;
        }

    .modal-login .hint-text a {
        color: #999;
    }

.login-btn {
    /*
		TODO:
		display: inline-block;
	margin: 100px auto;*/
}

.gdexBlue {
    background-color: #010b4c;
    color: white;
}


.gdexRed {
    background-color: #D71921;
    color: white;
}

html, body {
    max-width: 100%;
    /*overflow-x: hidden;*/
}


div.paddingTop {
    padding-top: 5px;
}

.paddingTopLogin {
    margin-top: 15px;
}

.required {
    color: red;
    font-size: 18px;
}





.sidebar {
    top: 80px;
}




.fullWidth {
    width: 100%
}

.width50px {
    width: 50px;
}

.width65px {
    width: 65px;
}


.flex {
    display: flex;
}


.paddingLeft {
    padding-left: 30px;
}

.paddingTop {
    padding-top: 30px;
}

.phoneButtonPrefixed {
    font-size: 12px;
    width: 100%;
    height: 50px;
    background-color: grey;
    color: white;
    border-width: 0px;
}

.gdexBlueButtonSquared {
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #010b4c;
    color: white;
    border-width: 0px;
    height: 40px;
    border-radius: 0.25rem;
}

.gdexRedButtonSquared {
    font-size: 16px;
    padding: 10px;
    background-color: #D71921;
    color: white;
    border-width: 0px;
}


.gdexBlueButtonLogin {
    font-size: 12px;
    font-family: 'Montserrat';
    font-weight: 900;
    padding: 10px;
    border-radius: 30px;
    background-color: #010b4c;
    color: white;
    border-width: 0px;
    height: 40px;
}

    .gdexBlueButtonLogin:hover {
        background-color: #010b4c;
        border-color: #010b4c;
        color: white;
    }

    .gdexBlueButtonLogin:focus {
        outline: 1px dotted;
    }



.gdexBlueButton {
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 900;
    padding: 10px;
    border-radius: 10px;
    background-color: #010b4c;
    color: white;
    border-width: 0px;
    height: 40px;
}

.gdexBlueButtonDisabled {
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 900;
    padding: 10px;
    border-radius: 10px;
    background-color: grey;
    color: white;
    border-width: 0px;
    height: 40px;
}

.gdexBlueButton:hover {
    border-color: #010b4c;
    color: white;
}

.gdexBlueButton:focus {
    background-color: #010b4c;
    color: white;
    outline: 0px dotted;
}

.gdexBlueButton:active {
    border-color: unset;
    border-width: 0px;
}


.gdexRedButton {
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 900;
    padding: 10px;
    border-radius: 10px;
    background-color: #D71921;
    color: white;
    border-width: 0px;
    height: 40px;
}

    .gdexRedButton:hover {
        background-color: #D71921;
        border-color: #D71921;
        color: white;
    }

    .gdexRedButton:focus {
        background-color: #D71921;
        color: white;
        outline: 0px dotted;
    }

    .gdexRedButton:active {
        border-color: unset;
        border-width: 0px;
    }


.roundedImage {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    margin-bottom: 15px;
    border-radius: 200px;
}

.disabledView {
    pointer-events: none;
    opacity: 0.9;
    cursor: not-allowed;
}

.text-red {
    color: #D71921;
}




/*
    Home Section
*/
.headerFlex {
    display: flex;
}


.myGdexLogoHome {
    margin-left: 100px;
}

.myGdexLogoHome1 {
    margin-left: 4px;
}

.profileleft {
    margin-left: 70%;
}

.headerNavBar {
    position: relative;
}

.brand {
    position: absolute;
    left: 50%;
    margin-left: -50px !important;
    display: block;
}

.headerNavBar1 {
    padding-left: 70%;
    background-color: #2f353a !important;
}

.marginRight {
    position: absolute;
    right: 10%;
}

.marginRight1 {
    position: absolute;
    right: 23%;
}

.paddingTop25 {
    margin-top: 15px !important;
    margin-left: 25px;
}


.homeMenu {
    font-weight: 900 !important;
    color: black !important;
    margin-left: 10px !important;
    font-family: 'Open Sans', sans-serif !important;
}


.ali {
    background-color: white;
    font-size: 12px;
    color: black;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.ali1 {
    background-color: white;
    font-size: 12px;
    color: black;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0px;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: unset;
}

.navbar-collapse {
    background: white;
}

.sidebar > .sidebar-nav {
    background: white !important;
}

.sidebar .nav-link {
    color: black !important;
    background: white !important;
}

    .sidebar .nav-link.active .nav-icon {
        color: #000000 !important;
        background: white !important;
    }

    .sidebar .nav-link:hover {
        color: black !important;
        background: lightgrey !important;
    }

.sidebar .nav-dropdown .nav-link {
    color: black !important;
    border-left: 0;
}

.sidebar .nav-dropdown.open .nav-link {
    color: black !important;
    border-left: 0;
}

.homeMenu:hover {
    color: black;
    text-decoration: none;
    background-color: none;
}

.roundedImage {
    margin-top: 25px !important;
    margin-left: 50px !important;
}

.quoteLabel {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    color: #010b4c;
    font-size: 23px;
    font-weight: 700;
}

.width15 {
    width: 18%
}

.width85 {
    width: 82%
}

/*
    Home Section
*/



/*
    Static Page
*/
.aboutUs-title {
    font-size: 1.5em;
    font-weight: bold;
}

.aboutUs-content {
    text-align: justify;
}

.why-integrate-boxShadow {
    box-shadow: 0 6px 15px 0 rgba(210,207,207,.81), 0 6px 10px 0 rgba(193,193,193,.1);
    padding: 15px;
}

ul.tick-ul {
    list-style: none;
    padding-left: 25px;
}

    ul.tick-ul li:before {
        margin-right: 10px;
        font-weight: bold;
        content: '✓';
    }

.subContent {
    color: #666
}

.whyWork-icon-container {
    display: inline-block;
    box-shadow: 0 6px 15px 0 rgba(210,207,207,.81), 0 6px 10px 0 rgba(193,193,193,.1);
    padding: 20px;
}

.whyWork-icon {
    max-width: 90px;
}

.indicator {
    background-color: red;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
}

.tab-title.active {
    font-weight: bold;
    border-color: #eaecee;
    color: #7a8994;
    background-color: #eaecee;
}

.guideLineRow {
    text-align: justify;
}

.nav-tabs ~ .tab-content {
    padding: 0;
}

.custom-container {
    margin-left: -15px;
    margin-right: -15px;
}

/*materialBoxed*/
.materialboxed {
    display: block;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    position: relative;
    -webkit-transition: opacity .4s;
    transition: opacity .4s;
    -webkit-backface-visibility: hidden
}

    .materialboxed:hover:not(.active) {
        opacity: .8
    }

    .materialboxed.active {
        cursor: -webkit-zoom-out;
        cursor: zoom-out
    }

#materialbox-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #292929;
    z-index: 1000;
    will-change: opacity
}

.materialbox-caption {
    position: fixed;
    display: none;
    color: #fff;
    line-height: 50px;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 0% 15%;
    height: 50px;
    z-index: 1000;
    -webkit-font-smoothing: antialiased;
    font-size: 1.2em;
}
/*materialBoxed*/

.ecommerce-container {
    box-shadow: 0.055rem 0.055rem 1.11rem rgba(20, 20, 20, 0.27);
    padding: 40px;
    cursor: pointer;
    user-select: none;
}

a.ecommerce-container {
    display: block;
    user-select: none;
}

.image-container {
    max-width: 100%;
    height: 60px;
}

.desc {
    font-size: 14px;
    font-weight: 500;
}

.bold {
    font-weight: 900;
}



@media (min-width: 1920px) {

    .footerPadding {
        position: relative;
        bottom: 0;
        width: 100%;
        background: #212121;
        /*margin-top: 10px;*/
        font-size: 13px;
        align-content: center;
    }

    .fffff {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}



@media (max-width: 1366px) {

    .footerPadding {
        /*margin-top: 10px;*/
        font-size: 13px;
        align-content: center;
        background: #212121;
    }

    .fffff {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.fixLeftTopButton {
    font-size: 2vh;
    background-color: white;
    border: white;
    position: absolute;
    top: 5px;
    right: 5px;
}

.textAlignLeft {
    text-align: left;
}


.fixLeftBottom {
    position: absolute;
    top: 15px;
    right: 5px;
}

.normalCursor {
    cursor: default;
}

.pointerCursor {
    cursor: pointer;
}

.ratebox {
    bottom: 10px;
    position: absolute;
}


.insuranceLabel {
    padding-right: 10px;
}

.prohibited-item-popover {
    max-height: 400px !important;
    overflow: auto !important;
}


/* FOR BLOG PAGE */

.blog-image {
    filter: blur(4px);
    -webkit-filter: blur(4px);
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(0.5);
}

/* Position text in the middle of the page/image */
.blog-text {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    text-align: center;
}

/* BLOG Social icon */
.icon-bar {
    text-align: center;
}

    .icon-bar a {
        display: block;
        text-align: center;
        padding: 5px;
        transition: all 0.3s ease;
        color: white;
        font-size: 20px;
    }

/* Blog img hover */
.figure {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0rem;
    margin-left: 0px;
}

.hover01 figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.hover01 figure:hover img {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}

.blog_img {
    background: #fff;
    /*border: 2px solid #e3e3e3;*/
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    /*-webkit-box-shadow: 0 4px 4px rgb(0 0 0 / 25%);*/
    /*box-shadow: 0 4px 4px rgb(0 0 0 / 25%);*/
    border-radius: 8px;
    font-weight: 400;
    text-align: center;
    margin: auto;
}

.blog_in {
    max-width: 100%;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    margin: 0;
}

.nav-linkBlog {
    /* display: block; */
    padding: 0px;
    /*    padding-left: 0px;
    padding-right: 0px;*/
}


.btn.spinning {
    position: relative;
    /* background-color: inherit; */
    padding-right: 40px !important;
}

    .btn.spinning:after {
        content: '';
        position: absolute;
        right: 6px;
        top: 50%;
        width: 0;
        height: 0;
        box-shadow: 0px 0px 0 1px;
        position: absolute;
        border-radius: 50%;
        animation: rotate360 .5s infinite linear, exist .1s forwards ease;
    }

    .btn.spinning:before {
        content: "";
        width: 0px;
        height: 0px;
        border-radius: 50%;
        right: 6px;
        top: 50%;
        position: absolute;
        border: 2px solid;
        border-right: 3px solid #27ae60;
        animation: rotate360 .5s infinite linear, exist .1s forwards ease;
    }


.react-datepicker__month-container {
    height: 265px;
}


.VideoContainer {
    position: relative;
}

    .VideoContainer videoC {
        position: relative;
        z-index: 0;
    }

.CustomOverlay {
    position: absolute;
    top: 15%;
    left: 20%;
    right: 20%;
    /*backdrop-filter: blur(2px);*/
}

/* Extra large devices (large laptops and desktops, 1800px and up) */
@media only screen and (min-width: 1800px) {

    .customVideo {
        /*height: 34rem;*/
        height: 90vh !important;
        width: 100%;
        object-fit: fill;
    }

    .customLogoSize {
        width: 175px;
        height: 98px;
        /*margin-bottom: 20px;*/
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .customVideo {
        /*height: 34rem;*/
        height: 85vh;
        width: 100%;
        object-fit: fill;
    }

    .customLogoSize {
        width: 175px;
        height: 98px;
        /* margin-bottom: 20px;*/
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .customVideo {
        /*height: 34rem;*/
        height: 85vh;
        width: 100%;
        object-fit: fill;
    }

    .customLogoSize {
        width: 175px;
        height: 98px;
        /*margin-bottom: 20px;*/
    }
}

/* Extra small devices (phones, 767px and down) */
@media only screen and (max-width: 767px) {

    .customHeight {
        padding: 0px !important;
    }

    .customToggleButton {
        position: fixed !important;
        top: 6% !important;
        left: 82% !important;
    }

    .customLogoSize {
        width: 150px;
        height: 80px;
    }

    .customFontSizeTitle {
        font-size: 1.5rem !important;
    }

    .customFontSizeSubTitle {
        font-size: 1.25rem !important;
    }

    .customFontSizeSpan {
        font-size: 14px !important;
    }

    .customVideo {
        /*height: 34rem;*/
        /*height: 90vh !important;*/
        width: 100%;
        margin-top: 8rem;
        /*object-fit: fill;*/
    }

    .CustomOverlay {
        position: absolute;
        top: 1% !important;
        left: 10%;
        right: 10%;
        /*backdrop-filter: blur(2px);*/
    }
}












/* Newest Design */



/* WhatsAppWidget */
._1bpcM, ._2iWL7 {
    z-index: 5;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins';
    color: #000000;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 26px;
}

h3 {
    font-size: 25px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {
    h1 {
        font-size: 20px;
    }

    p, span, li {
        font-size: 14px;
    }

    .navbar-collapse {
        background: #F9F9F9;
    }
}

.btnInsubee {
    font-size: 18px;
    text-align: center;
    background-color: #FFDF00;
    font-family: 'Poppins';
    font-weight: bolder;
    border-radius: 50px;
    width: 100%;
    height: 48px;
    color: black;
    border: 1px solid #FFDF00;
}

    .btnInsubee:hover {
        color: #FFDF00;
        background-color: black;
    }

.btnInsubeeR {
    font-size: 18px;
    text-align: center;
    background-color: black;
    font-family: 'Poppins';
    font-weight: bolder;
    border-radius: 50px;
    width: 100%;
    height: 48px;
    color: #FFDF00;
    border: 1px solid #FFDF00;
}

    .btnInsubeeR:hover {
        color: black;
        background-color: #FFDF00;
        border: 4px solid #000000;
    }

.removeBtn {
    font-size: 15px;
    text-align: center;
    background-color: red;
    position: absolute;
    margin-top: -45px;
    margin-left: 650px;
    border-radius: 50px;
    width: 110px;
    height: 38px;
    padding-top: 7px;
    cursor: pointer;
    color: white;
}

    .removeBtn:hover {
        transform: translateY(-2px);
        transition: all 0.5s;
    }

/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {
    .removeBtn {
        position: initial;
        margin-top: 0.5rem;
        margin-left: 480px;
    }
}

.roundedButton {
    font-size: 18px;
    text-align: center;
    background-color: #FFDF00;
    margin-left: 25px;
    padding-top: 10px;
    border-radius: 50px;
    width: 190px;
    height: 48px;
}

    .roundedButton a {
        text-decoration: none;
        color: black;
    }

    .roundedButton:hover {
        background-color: black;
    }

#IBroundedButton:hover > #IBbuttonlink {
    color: #FFDF00 !important;
}

.roundedButton a:hover {
    color: #FFDF00 !important;
}

.form-control {
    display: block;
    width: 100%;
    height: 50px;
    padding: 0.3rem 1.3rem;
    font-size: 1rem;
    font-weight: 400;
    background-clip: padding-box;
    border: 1px solid #575757;
    border-radius: 50px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

    .input-group > .custom-select:not(:last-child) {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
    }

/*.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
    }*/

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #575757;
    border-right-color: #e9ecef;
    border-radius: 50px 0px 0px 50px;
}

.input-group-textbox {
    border: 1px solid #575757;
    border-left-color: #e9ecef;
    border-style: solid;
    border-width: thin;
    border-radius: 0px 50px 50px 0px;
}


/* rounded dropdwon */
.custDropDown .rw-widget-picker {
    display: grid;
    overflow: hidden;
    min-height: 38px;
    border: #575757 1px solid;
    outline: none;
    grid-template: 1fr/1fr 1.9em;
    border-radius: 50px;
    height: 50px;
    width: 313.15px;
}

.rw-popup {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin-top: 2px;
    border-radius: 4px;
    box-shadow: 0 0.5em 1em rgb(0 0 0 / 18%);
    border: #ccc 1px solid;
    background-clip: padding-box;
    background: #fff;
    width: 313.15px;
}

.rw-dropdown-list-input > * {
    grid-area: 1/1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0.375rem 0.75rem;
}


/* rounded custom checkbox */

.containerCheckBox {
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    /* Hide the browser's default checkbox */
    .containerCheckBox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 2px #665F5D solid;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.containerCheckBox:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked add grey background */
.containerCheckBox input:checked ~ .checkmark {
    background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.containerCheckBox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.containerCheckBox .checkmark:after {
    left: 5px;
    top: 3px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    transform: rotate(35deg);
}

.btn-secondary.focus, .btn-secondary:focus {
    outline: none;
    box-shadow: none;
    color: #FFDF00;
    background-color: black;
    border-color: black;
    /* box-shadow: 0 0 0 .2remrgba(130,138,145,.5);*/
}

/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {
    .custDropDown .rw-widget-picker, .rw-popup {
        width: 100% !important;
    }
}
