.fixed-top {
    height: 130px !important;
    padding: 0;
    flex-flow: wrap;
}

html {
    font-size: 14px;
}

@media (min-width: 576px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.dropbtn {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-weight: 900 !important;
    color: black !important;
    margin-left: 10px !important;
    font-family: 'Open Sans', sans-serif !important;
    align-self: center;
    font-size: 16px;
    padding-top: 0.5rem;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    color: black;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    text-align: center;
    margin-left: -20px;
    top: auto !important;
    padding: inherit !important;
}

    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

        .dropdown-content a:hover {
            background-color: #ddd;
        }

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .CustomSpan {
    color: #007bff !important;
}

.homeMenu2 {
    font-weight: 900 !important;
    color: black !important;
    font-family: 'Open Sans', sans-serif !important;
}

    .homeMenu2:hover {
        color: black;
        text-decoration: none;
        background-color: none;
    }



















/* Newest Design */

.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-left: 4rem;
    margin-right: auto !important;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.pageTop {
    height: 40px;
    padding: 0.6rem;
    text-align: center;
    color: white;
    font-size: 13px;
    font-family: "open sans";
    background-color: black;
    width: 100%;
}

.LSu {
    font-size: 13px;
    color: white;
    text-decoration: none !important;
}

    .LSu:hover {
        color: #FFDF00 !important;
    }

.Float1 {
    width: 100%;
}

.Float2 {
    position: relative;
    margin-left: 80%;
    bottom: 1.2rem;
}

.flexContainer {
    font-size: 16px;
    font-family: "Poppins";
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 90px;
    box-shadow: 0 6px 2px -2px #dddcdc;
    /*flex-wrap: nowrap;*/
}

    .flexContainer > div {
        width: 16%;
        text-align: center;
        font-size: 16px;
    }

#InsuBeeLogo {
    flex: 1 25%;
}

#NavItems {
    flex: 1 75%;
}

.fNavItems {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-right: 6rem;
}

    .fNavItems a {
        text-decoration: none;
    }

        .fNavItems a:hover {
            color: #FFDF00 !important;
        }

    .fNavItems > * {
        font-weight: bold;
        padding-top: 1rem;
        color: black;
    }

.fNItems3 {
    flex: 3;
}

.fNItems2 {
    flex: 2;
}

.login-display {
    display: none;
}

#emailDotIcon {
    font-size: 2rem;
    color: black;
    float: left;
    margin-left: 10px;
    /* margin-right: 10px;
    margin-top: 10px;*/
}

.EIcon > img:nth-child(2) {
    display: none;
    width: 24px;
    height: 18px;
}

.EIcon:hover img:nth-child(2) {
    display: inline-block;
    width: 24px;
    height: 18px;
}

.EIcon:hover img:first-child {
    display: none;
}

span small {
    display: block;
}

/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {
    #InsuBeeLogo {
        order: 2;
        flex: 1 75%;
    }

    #NavItems {
        order: 1;
        flex: 1 25%;
        margin-left: -15px;
    }

    .navbar-collapse {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        overflow-x: hidden;
        transition: 0.5s;
        text-align: left;
        padding: 55px;
    }

    .navBar-bgImg {
        background-position: 230% 100% !important;
        background-image: url(/static/media/TestimonialsInsubeeLogoBG.76dff718.svg) !important;
        background-repeat: no-repeat !important;
    }

    .fNItems3 {
        display: none;
    }

    .fNavItems {
        height: 100vh;
    }

        .fNavItems > * {
            font-family: 'Poppins';
            font-size: 18px;
            padding: 2rem;
        }

    .fNItems2 {
        flex: 0;
    }

    .login-display {
        display: block;
    }

    .EIcon {
        margin-top: 2rem;
        margin-left: 0px !important;
    }

    .roundedButton.EIcon {
        margin-top: 2rem;
        padding-bottom: 10px;
    }

    .pageTop .Float2 {
        display: none;
    }
}


/* footer */

.pageBottom {
    background-color: #f0f0f0fa;
    width: 100%;
    height: 122.19px;
}

.BenefitsList {
    display: flex;
    justify-content: space-between;
    height: 122.19px;
    padding: 10px 70px;
    line-height: 1rem;
}

    .BenefitsList div {
        margin: 10px;
        height: 87px;
        width: 318px;
    }

    .BenefitsList img {
        float: left;
        width: 87px;
        height: 87px;
    }

    .BenefitsList span {
        font-size: 14px;
    }

    .BenefitsList h6 {
        padding-top: 10px;
        color: #EECF00;
        font-weight: bolder;
    }

/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {
    .various0 {
        display: none;
    }

    .various1 {
        display: none;
    }

    .various2 {
        display: none;
    }

    .BenefitsList {
        padding: 10px 30px;
    }

        .BenefitsList div {
            width: 100%;
        }
}

footer {
    background-color: white;
    color: black;
}

    footer span {
        font-size: 14px;
    }

    footer * {
        font-family: "Open Sans";
        box-sizing: border-box;
        border: none;
        outline: none;
    }

    footer .row {
        /* padding: 1em 1em;*/
        padding: 0px;
        margin: 0px;
    }

.footer-email-input {
    width: 323px !important;
    height: 38px !important;
}

#emailID::placeholder {
    color: #EECF00;
    opacity: 1;
    font-weight: bolder;
}

.about img {
    width: 195px;
    height: 110px;
    margin-top: -20px;
}

.about > .social img {
    width: 17.8px;
    height: 17.8px;
}

.row.primary {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 2fr;
    align-items: stretch;
    padding: 35px 50px 0px 50px;
    height: 276px;
}

@media screen and (max-width: 850px) {
    .row.primary {
        grid-template-columns: 1fr;
    }
}

.primary .column {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 2em;
    min-height: 15em;
}

.links span {
    width: 100%;
    text-align: left;
    color: black;
    font-size: 14px;
    white-space: nowrap;
    margin-bottom: 15px;
}

.subscribe span:first-child {
    text-align: left;
    color: black;
    font-size: 14px;
    white-space: nowrap;
    margin-bottom: 15px;
}

.arrow-input {
    border-left-color: #e9ecef;
    border-radius: 0px 50px 50px 0px;
    border-right-color: #575757;
}

.links ul {
    list-style: none;
    flex-direction: column;
    padding: 0;
    margin: 0.5rem 0 0 0;
}

.links li:not(:first-child) {
    margin-top: 0.8em;
}

.links ul li a {
    color: black;
    text-decoration: none;
}

    .links ul li a:hover {
        color: #2a8ded;
    }

.about p {
    text-align: justify;
    line-height: 2;
    margin: 0;
}

.company-column #COMPANY, #RESOURCES, #PAYMENT {
    display: none;
}

/* Media Query for smaller screens */
@media only screen and (max-width: 767px) {
    .dply-none
    /* Here I have many other divs like below */ {
        display: none;
    }

    #COMPANY:checked + label > ul, #RESOURCES:checked + label > ul, #PAYMENT:checked + label > ul {
        display: block;
    }

    .row.primary {
        padding: 10px;
        height: 100%;
        text-align: center;
    }

    footer .row {
        flex-wrap: nowrap;
        margin-bottom: 2rem;
    }

    .about img {
        margin: auto;
    }

    .primary .column {
        padding: 0.5em 2em;
        min-height: 2em;
    }

    .subscribe {
        margin-top: 1rem;
    }

        .subscribe span:first-child {
            text-align: center;
        }

        .subscribe div:nth-child(2) {
            width: 238px;
            margin: auto;
        }
}


/*input,
button {
    font-size: 1em;
    padding: 1em;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 5px;
}*/

/*button {
    background-color: #c7940a;
    color: #ffffff;
}*/

div.social {
    margin-top: 1em;
    padding-top: 1rem;
    margin-left: -20px;
}

.social i {
    color: #bac6d9;
}

.copyright {
    /* padding: 0.3em 1em;*/
    background-color: black;
    height: 44px;
    display: block;
    text-align: center;
    padding: 10px 0px !important;
}

    .copyright span {
        font-size: 12px;
        color: white;
    }

/*.footer-menu {
    float: left;
    margin-top: 10px;
}

    .footer-menu a {
        color: #cfd2d6;
        padding: 6px;
        text-decoration: none;
    }

        .footer-menu a:hover {
            color: #27bcda;
        }*/






.w3-border-right {
    border-right: 1px solid #ccc !important;
}

.w3-sidebar {
    height: 100%;
    width: 200px;
    background-color: #fff;
    position: fixed !important;
    z-index: 8;
    overflow: auto;
    padding-top: 70px;
}

.w3-bar-block .w3-bar-item {
    width: 100%;
    display: block;
    padding: 8px 16px;
    text-align: left;
    border: none;
    white-space: normal;
    float: none;
    outline: 0;
}


.w3-large {
    font-size: 18px !important;
}

.w3-bar-block .w3-bar-item {
    width: 100%;
    display: block;
    padding: 8px 16px;
    text-align: left;
    border: none;
    white-space: normal;
    float: none;
    outline: 0;
}

.sideNavBtn {
    width: 100px;
    /*background-color: #FFDF00 !important;*/
    border: 1px solid black;
    background-color: black !important;
    color: white !important;
    position: fixed !important;
    z-index: 8;
    overflow: auto;
    margin-top: 75px;
}



/* Landing Page A & B */

.fNavItems-variation {
    margin-left: 40%;
}

.fNavItems-variationB {
    margin-left: 60%;
}

.flexContainer.variation-header {
    height: 130px;
}

.main-footer-variation {
    align-items: center;
    padding: 2rem 3rem;
    background-color: black;
    color: white;
}

.footerVariation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Allow items to wrap to next line */
}

.leftSide-footerVariation {
    width: 636px;
}

    .leftSide-footerVariation img {
        height: 40px; /* Adjust height as needed */
    }

    .leftSide-footerVariation p {
        font-size: 14px;
    }

.right-footerVariation {
    display: flex; /* Display the elements in the right side as flex */
}

    .right-footerVariation p {
        margin: 0;
        padding: 0 10px;
    }

    .right-footerVariation a {
        font-size: 14px;
        margin-right: 25px;
        margin-top: 5px;
    }

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
    .footerVariation {
        flex-direction: column; /* Stack items vertically */
        margin-bottom: 2rem;
    }

    .leftSide-footerVariation {
        width: 100%;
        margin-bottom: 10px; /* Add some spacing between left and right sides */
    }

    .fNavItems-variation, .fNavItems-variationB {
        margin-left: 0;
    }
}
